.mainContainer {
  display: flex;
  flex-direction: row;
  margin: 1.3rem 0;
}

.chatIcon {
  height: 65px;
  padding-top: 5px;
  padding-right: 7px;
}

.textbox {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 22px 0 22px 22px;
  padding: 10px 18px;
  font-size: 1rem;
  flex: 12;
}

.questions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 2;
  flex-wrap: wrap;
}

.button {
  background-color: transparent;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 10px;
  padding: 6px 16px;
  margin-top: 0.5rem;
  color: rgb(0, 0, 0);
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  font-family: inherit;
  width: 19.5%;
}

.button:hover {
  color: var(--main-color);
  border-color: var(--main-color);
  text-decoration: none;
}

.button + .button {
  margin-left: 0.3rem;
}

.button:nth-child(6) {
  margin-left: 0;
}

.active {
  background-color: var(--main-color);
  color: white;
  border-color: var(--main-color);
}

.active:hover {
  background-color: var(--main-color);
  color: white;
  border-color: var(--main-color);
  text-decoration: none;
  cursor: default;
}
