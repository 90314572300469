.switch_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.3s linear;
  /* max-height: calc(23.5vh + 23px); */
  align-items: stretch;
  /* margin-top: 50px; */
}

/* .results {
  --height: 12vh
} */

.results p, .results h3 {
  font-size: 14px
}

.switch_container > :not(:last-child) {
  margin-right: 0.5rem;
}

.switch_container > * {
  justify-content: center;
}

.slider_container {
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
}

.slider {
  width: 200%;
  display: flex;
  flex-direction: row;
  transition: transform 0.3s ease-in-out;
}

.chart_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 3rem;
  width: 50%
}



.chart {
  width: 50%;
  height: 35vh;
}

.joint_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.joint_container :first-child {
  margin-right: 0.5rem;
}

.chart2 {
  width: 66%;
  height: 30rem;
}

.ret_switch_and_warning_container {
  width: 100%;
  display: flex;
  align-items: stretch;
  margin-top: 1%;
  height: 21vh;
}

.table_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%
}