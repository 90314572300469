@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');  

/* * {
  outline: 1px solid black;
} */

html {
  font-family: Montserrat, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  /* line-height: 1.5; */
}



body {
  padding: 0;
  border: 0;
  margin: 5.75rem auto 3rem auto;
  align-self: center;
  width: 60%;
  min-width: 900px;
  max-width: 1350px; 
  overflow-y: scroll;
  --background-default: rgba(118, 134, 146, 0.2);
  --border-default: rgb(118, 134, 146);
  --background-hovered: rgba(86, 167, 175, 30%);
  --border-hovered: rgba(86, 167, 175, 60%);
  --background-selected: rgb(0, 124, 143);
  --border-selected:  rgba(86, 167, 175, 20%);
  --deep-blue: rgb(0,94,184);
  --main-color: #004158;
  --main-color-light: #a6c7c9;
  --gad-teal: #007c8f;
  --sppa-medium-light: #56A7AF;
  --light-grey: #768692;
  --full-legacy: #56a7af;
  --full-legacy-light: rgba(86,167,175,.5);
  --legacy-curtailed: #56a7af;
  --legacy-curtailed-light: rgba(86,167,175,.5);
  --CARE: #007c8f;
  --CARE-light: #007c8f;
  --CARE-super-light: #a6c7c9;
  --very-light-grey: #e8edee;
}

@media (max-width: 1880px) {
  html {
    font-size: 15px;
  }
  body {
    margin: 6.75rem auto 2rem auto;
    width: 75%;
  }
}

@media (max-width: 1000px) {
  body {
    margin: 6.5rem 1.5rem 1rem 1.5rem;
    width: 60% !important;
    max-width: 800 !important;
  }
}

h1 {
  font-size: 1.3rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}

button {
  font-family: inherit;
}

p,
button {
  font-size: 1rem;
}

h1,
h2,
h3,
p,
ul {
  margin: 0 0 0.75rem 0;
}

p:last-child,
p:only-child {
  margin: 0;
}

table,
th,
td {
  border-collapse: collapse;
  margin: 0 0 0.75rem 0;
  font-size: 15px;
  border: 1px solid;
  padding: 0.5rem;
}

a {
  color: rgb(0,65,88);
}
