.service_container {
  background-color: rgb(242, 242, 242);
  border-radius: 6px;
  padding: 12px 18px;
  margin-top: 0.3rem;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  transition: all 0.4s linear;
  max-height: 1000px;
}

.service_container p, .service_container label, .service_container h3, .service_container button {
  font-size: 1.25rem
}
.service_container.invisible {
  max-height: 0;
  overflow:hidden;
  padding:0;
  opacity: 0;
  margin-top: 0
}

.service_container section {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.service_container button {
  cursor: pointer;
    background-color: var(--main-color);
    color: rgb(255, 255, 255);
    padding: 0rem 1rem;
    border-radius: 4px;
    font-weight: bold;
    height: 2.3rem;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 100%;
    height: calc(100% - 30px);
}

.service_container button:hover {
  border: 2.5px solid 86,167,175;
  background-color: 86,167,175;
  opacity: 0.85;
}

.service_container table {
  background-color: white;
  table-layout: fixed;
  width: 95%;
  text-align: center;
  border: none;
  margin: 1rem;
  font-size: 1.25rem
}

.service_container th,
.service_container td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 1.25rem
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  min-height:20vh;
  padding-bottom: 3%;
  padding-left: 5%;
  padding-right: 5%;

}

.leftColumn {
  background-color: rgb(242, 242, 242);
  border-top-color: rgb(242, 242, 242);
  border-left-color: rgb(242, 242, 242);
  border-bottom-color: rgb(242, 242, 242);
  text-align: right;
  width: 1rem;
}

.rightColumn {
  background-color: rgb(242, 242, 242);
  border-top-color: rgb(242, 242, 242);
  border-right-color: rgb(242, 242, 242);
  border-bottom-color: rgb(242, 242, 242);
  text-align: left;
  width: 1rem;
}

.subContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.label_input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.label_input label {
  min-width: 20%;
  text-align: center;
  margin-bottom: -1%;
}

.input {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid rgb(204, 204, 204);
  padding: 0.25rem;
  width: 25%;
  height:1.75rem !important;
  margin: 0;
}

.table_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.line {
  width: 100%;
  height: 1.5px;
  background: black;
}

.button_container {
  width: 100%;
  display: flex;
  justify-content: center;
}