.download_button {
    padding: 0.25rem;
    color: hsl(0, 0%, 66%);
    border: 2px solid hsl(0, 0%, 66%);
    background-color: hsla(0, 0%, 66%, 0.2);
    border-radius: 4px;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    width: 30%;
    height: 50px
}

.download_button:hover {
    color: var(--border-hovered);
    border-color: var(--background-hovered);
    text-decoration: none;
    background-color: var(--border-hovered);
    cursor: pointer;
}

.download_button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px
}