.retirement_age_warning {
    width: 100%;
    margin-left: 1%;
    border: 1.5px solid black;
    border-radius: 4px;
    transition: all 0.6s linear;
    overflow-y: auto;
}

.retirement_age_warning img {
    width: 40px;
    height: 30px
}

.retirement_age_warning h2 {
    height: 10%;
    margin-top: 1.5%;
    text-align: center
}

.retirement_age_warning > div {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.image_container {
    margin-left: 5%;
    display: flex;
    align-items: center
}

.image_container button {
    margin-left: 1%;
    text-align: center;
    width: calc(90% - 30px);
    margin-left: 3%;
    padding: 1%;
    border: 2px solid red;
    border-radius: 20px;
    background: white;
    color: red;
    font-weight: 700;
    transition: transform 0.1s linear;
}

.image_container button:hover {
    cursor: pointer;
    background: lightcoral;
    color: white;
    transform: scale(1.05)
}

.bullets {
    text-align: left
}

.bullets li {
    margin-bottom: 1em
}

.warnings_container > div {
    width: 0%
}

.warnings_container > div.visible {
    width: 100%
}

.first_warning, .second_warning, .third_warning, .fourth_warning {
    opacity: 0;
    transition: all 0.3s linear;
    max-height: 0px;
    transform: scaleY(0)
}

.first_warning.visible, .second_warning.visible, .third_warning.visible, .fourth_warning.visible {
    opacity: 1;
    max-height: 130px;
    height: 55px;
    transform: scaleY(1)

}