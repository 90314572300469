.inputs_reminder {
    width: 100%;
    height: 28vh;
    margin-top: 10px;
    border: 1.5px solid black;
    border-radius: 4px;
    position: relative;
    margin-top:50px;
}

.inputs_reminder h2 {
    text-align: center;
    height: 20%;
    margin: 0;
    width: 90%;
    margin-left: 5%;
    margin-top: 1%;
    margin-bottom: 1vh
}

.with_status {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates 4 columns, equally spaced */
  grid-auto-rows: auto; /* Automatically adjusts the row height */
  gap: 0px; /* Adjust the gap between grid items as needed */
  height: 65%;
}

.with_status > div:nth-child(5), 
.with_status > div:nth-child(6), 
.with_status > div:nth-child(7) {
grid-column: span 1; /* Makes the last row items take up the full row */
width: 150%;

}

.with_status > div:nth-child(5) {
grid-column-start: 1; /* Aligns the first child of the second row to start from the first column */
transform: translateX(0%);
}

.with_status > div:nth-child(6) {
grid-column-start: 2; /* Aligns the second child of the second row to start from the second column */
transform: translateX(15%);
}

.with_status > div:nth-child(7) {
grid-column-start: 3; /* Aligns the third child of the second row to start from the third column */
transform: translateX(30%);
}

.without_status {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 4 columns, equally spaced */
  grid-auto-rows: auto; /* Automatically adjusts the row height */
  gap: 0px; /* Adjust the gap between grid items as needed */
  height: 65%;
}

.without_status > div:nth-child(4), 
.without_status > div:nth-child(5), 
.without_status > div:nth-child(6) {
grid-column: span 1; /* Makes the last row items take up the full row */
width: 100%;

}

.button_container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.button_container > div> p {
    text-align: center;
    width: 80%;
    margin-bottom: 0;
}

.button_container button {

    color: hsl(0, 0%, 66%);
    border: 2px solid hsl(0, 0%, 66%);
    background-color: hsla(0, 0%, 66%, 0.2);
    border-radius: 4px;
    font-weight: 600;
    text-align: center;
    width: 80%;
    height: 50%;
    font-size: 1.25rem
}

.button_container button:hover {
    color: hsl(205, 58%, 59%);
    border-color: hsl(205, 58%, 59%);
    text-decoration: none;
    background-color: hsla(205, 58%, 59%, 0.2);
    cursor: pointer;
}

.status_missing_warning {
  position: absolute;
  top: calc(50% - 25px);
  left: 10%;
  background: crimson;
  height: 60px;
  width: 80%;
  padding: 10px;
  text-align: center;
  border: 2px solid black;
  border-radius: 10px;
  transition: transform 0.2s linear
}

.status_missing_warning:hover {
  cursor:pointer;
  transform: scale(1.05)
}

.service_pt_compatibility {
  width: 80%;
  margin-left: calc(10% - 20px);
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.25rem;
  font-weight: 600;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px 20px
}