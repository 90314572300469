.pagebutton {
  font: inherit;
  cursor: pointer;
  background-color: var(--main-color);
  color: rgb(255, 255, 255);
  padding: 0.5rem 1.5rem;
  border: 2.5px solid var(--main-color);
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
}

.pagebutton:hover {
  color: rgb(255, 255, 255);
  border: 2.5px solid var(--main-color);
  background-color: var(--main-color);
  opacity: 0.85;
}
