:focus {
    outline: none;
}

/* necessary to give position: relative to parent. */
.effect-19 {
    color: var(--text-color);
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
    border: 1px solid var(--border-color);
    border-radius: 1em;
    padding: 7px 14px;
    transition: 0.8s;
    background: white;
}

.col-3 {
    width: var(--box-width);
    position: relative;
    margin-top: 30px;
}

.col-3 input[type="text"] {
    width: 100%;
    border-radius: 1em;
    box-sizing: border-box;
    letter-spacing: 1px;
    border: 1px solid var(--border-color); /* Ensure the border is applied here */
    padding: 7px 14px; /* Add padding to ensure text is not too close to the border */
}

.col-3 input {
    font-size: 1.1rem;
}

.effect-19 ~ .focus-border {
    display: none; /* Hide the focus-border for simplicity */
}

.effect-19 ~ label {
    margin-left: var(--left-margin);
    position: absolute;
    left: 0%;
    width: 100%;
    top: 20%;
    color: var(--text-color);
    transition: 0.6s;
    z-index: 1;
    letter-spacing: 0.5px;
    pointer-events: none;
    font-size: 1.25rem;
}

.effect-19:focus ~ label,
.has-content.effect-19 ~ label {
    top: -18px;
    left: 0;
    font-size: 12px;
    color: var(--text-color);
    transition: 0.6s;
}

.mahi_holder {
    width: 100%;
    display: var(--centralise);
    justify-content: center;
}

.mahi_holder.col-3 input {
    margin-top: 0;
    height: 30px;
    border-radius: 1em;
}
