.table_container {
    border-radius: 10px;
    overflow: hidden;
    height: 80%
}

.main_table {
    width: 80%;
    min-height: 100%
}

.main_table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    padding: 0;
    overflow: visible;
    border-radius: 6px;
    border: none;
    margin-bottom: 80px;
    font-size: 1.25rem;
  }
  
  .main_table thead,
  .tabmain_tablele tbody,
  .main_table td {
    border: none;
    text-align: center;
  }

  
  /* .main_table td:nth-of-type(3n + 0) {
    border-right: 1px solid black;
  } */
  
  .main_table thead {
    /* border: 1px solid black;
    border-radius: 6px; */
    background-color: var(--main-color);
    font-weight: bold;
    color: rgb(255, 255, 255);
  }
  
  .main_table tbody tr:nth-child(even) {
    background-color: rgba(168, 168, 168, 0.2);
  }