.dropdown_wrapper {
  position: relative;
  margin-top : 10px;
  margin-bottom: 10px;
}

.dropdown-el {
  width: 100%;
  top: 0;
  left: 0;
  display: inline-block;
  margin-right: 1em;
  min-height: 100%;
  max-height: 100%;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  outline: none;
  border: 0.06em solid transparent;
  border-radius: 1em;
  background-color: var(--color);
  transition: 0.3s all ease-in-out;
  font-size: 1.1rem;
  box-shadow: 5px 5px var(--shadow-color);
}

.dropdown-el:hover {
  color: var(--border-hovered);
  background: var(--background-hovered);
  box-shadow: 0px 0px var(--border-hovered);
}

.dropdown-el input:focus + label,
.dropdown-el label:hover {
  background: #def;
}

.dropdown-el input {
  width: 1px;
  height: 1px;
  display: inline-block;
  position: absolute;
  opacity: 0.01;
}

.dropdown-el label {
  border-top: 0.06em solid #d9d9d9;
  display: block;
  height: 2em;
  line-height: 2em;
  padding-left: 1em;
  padding-right: 3em;
  cursor: pointer;
  position: relative;
  transition: 0.3s color ease-in-out;
}

.dropdown-el label:nth-child(2) {
  border-top: 0.06em solid #d9d9d9;
}

.dropdown-el input:checked + label {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
}

.dropdown-el input:checked + label:nth-child(2) {
  position: relative;
}

.dropdown-el::after {
  content: "";
  position: absolute;
  right: 0.8em;
  top: 0.9em;
  border: 0.3em solid #3694d7;
  border-color: #3694d7 transparent transparent transparent;
  transition: 0.4s all ease-in-out;
}

.dropdown-el.expanded {
  overflow-y: scroll;
  overflow-x: hidden;
  border: 0.06em solid #3694d7;
  background: #fff;
  border-radius: 0.25em;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 0px;
  max-height: 15em;
  position: absolute;
  z-index: 3;
  text-align: center;
}

.dropdown-el.expanded::-webkit-scrollbar {
  width: 20px; /* Width of the scrollbar */
  background-color: var(--background-hovered); /* Background color of the scrollbar */
}

/* Style the scrollbar thumb (the draggable part) */
.dropdown-el.expanded::-webkit-scrollbar-thumb {
  background-color: var(--background-default); /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners of the scrollbar thumb */
  border: 2px solid var(--border-default); /* Creates a border around the thumb */
}

/* Style the scrollbar thumb when hovering over it */
.dropdown-el.expanded::-webkit-scrollbar-thumb:hover {
  background-color: var(--border-selected); /* Color of the scrollbar thumb when hovered */
  border-color: var(--background-selected);
}

.dropdown-el.expanded label {
  border-top: 0.06em solid var(--border-default);
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0;
}

.dropdown-el.expanded label:hover {
  color: #3694d7;
}

.dropdown-el.expanded input:checked + label {
  color: #3694d7;
}

.dropdown-el.expanded::after {
  transform: rotate(-180deg);
  top: 0.55em;
}

.sub_container {
  transition: max-height 0.3s linear;
}

.visible {
  max-height: 1000px;
}

.invisible {
  max-height: 0;
  opacity: 0;
}

.dropdown_container p {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown_container {
  position: relative;
  width: 100%;
  height: 30px;
  flex: 0.3;
  margin-top: 5px;
}

.customInputContainer {
  padding: 8px;
  position: relative;
  top: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
}

.customInput {
  margin-top: 8px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}
