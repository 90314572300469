.multiple_choice {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top : 10px;

    min-height: 70px;
    align-items: center
}

.multiple_choice button {
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 10px;
    font-size: 1.1rem;
    min-height: 30px;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 700;
    transform: translateX(-5px) translateY(-5px);
    transition: all 0.2s linear;
    font-family: inherit;
}

.multiple_choice button.unpressed:hover {
    background: var(--background-hovered);
    border: 1.5px solid var(--border-hovered);
    color: var(--border-hovered);
    cursor: pointer;
    transform: translateX(-5px) translateY(-5px);
    font-weight: 700;
    transform: translateX(0) translateY(0);
    
}

 .multiple_choice button.pressed {
    background: var(--border-selected);
    border: 1.5px solid var(--background-selected);
    color: var(--background-selected);
    transform: translateX(0) translateY(0);
}

 .multiple_choice button.unpressed {
    background: var(--background-default);
    border: 1.5px solid black;
    color: black;
    font-weight: 700;
 }