.buttons_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: calc(80% - 40px);
    margin-top: 25px;
    margin-left: 10%;
    background: rgba(200,200,200,0.3);
    box-shadow: 0px 0px 2px 2px grey;
    padding: 20px;
    border-radius: 10px;

  }

 

  .buttons_container button {
    display: inline-block;
    padding: 0.1rem;
    color: white;
    border: none;
    background-color: var(--deep-blue);
    opacity: 1;

    border-radius: 10px;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    width: 80%;
    margin-left: 10%;
    height: 50px;
    transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95)
}

.buttons_container p {
  margin-left: 10%;
  width: 80%;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  color: black
}

.buttons_container button:hover {
    color: white;
    border-color: hsl(205, 58%, 59%);
    text-decoration: none;
    background-color: hsla(205, 58%, 59%);
    cursor: pointer;
    transform: scale(1.05)
}