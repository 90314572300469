.show_as_container {
    border-radius: 6px;
    border: 1.5px solid rgb(0, 0, 0);
    padding: 10px 15px;
    height: var(--height);
    transition: all 0.5s linear;
    width: calc(30% - 33px);
    margin-right: 1%;
    height: calc(-23px + 21vh);
}

.show_as_container h2 {
    height: 20%;
    text-align: center
}
.show_as_container > div:first-of-type {
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30%;
    margin-top: -7%;
}

.show_as_container > p:first-of-type {
    margin-top: 10%;
    margin-bottom: 4%;
}

.show_as_container button {
    display: inline-block;
    padding: 0.3rem;
    color: hsl(0, 0%, 66%);
    border: 2px solid hsl(0, 0%, 66%);
    background-color: hsla(0, 0%, 66%, 0.2);
    border-radius: 4px;
    font-weight: 600;
    flex: 1 1;
    text-align: center;
    font-size: 1.2rem;
    width: 80%;
    flex: 0.4
}

.show_as_container button:hover {
    color: var(--border-hovered);
    border-color: var(--border-hovered);
    text-decoration: none;
    background-color: var(--background-hovered);
    cursor: pointer;
}

.show_as_container button.selected {
    color: var(--background-selected);
    border-color: var(--background-selected);
    background-color: var(--border-selected)
}

.fancy_toggle_container {
    height: 20%;
    width: 115%;
    flex: 0.3;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: -7.5%;
}

.fancy_toggle_container p {
    margin: 0;
}

.more_options {
    margin-top: 5%;
    margin-bottom:0;
    width: 115%;
    margin-left: -7.5%;
    text-align: center;
    font-weight: 600
}