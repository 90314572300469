.input_container {
  background-color: rgb(242, 242, 242);
  border-radius: 6px;
  padding: 12px 18px;
  margin: 0.3rem auto;
  width: 80%;
}

.button_container {
  margin: 0.5rem auto;
  width: calc(80% + 36px);
  display: flex;
  justify-content: flex-end;
}


.yourDetails {
  overflow: hidden;
  margin-bottom: 100px;
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .yourDetails {
    min-height: calc(var(--mh) * 1.5)
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .yourDetails {
    min-height: calc(var(--mh) * 1.25)
  }
}

@media screen and (min-width: 1200px) {
  .yourDetails {
    min-height: calc(var(--mh))
  }
}

.service_too_low {
  width: 80%;
  margin: 0.3rem auto;
  border: 2px solid red;
  padding: 10px;
  border-radius: 10px;
  font-size: 18px;
  color: red;
}